import React from 'react'

import SEO from '../../components/seo'

import LayoutPayer from '../../pageLayouts/LayoutProducts/LayoutPayer'

const PayerQualityofAccessPage = () => (
  <div>
    <SEO
      title="Data Analytics and Decision Support Platform for Market Access"
      keywords={[
        'pulse digital',
        'healthcare',
        'react',
        'software engineering',
        'new york',
        'nyc',
        'pulse',
        'javascript',
        'analytics',
        'development',
        'payer',
        'payer polices',
        'formulary',
        'tracking',
        'quality of access',
        'payer access',
      ]}
    />
    <LayoutPayer />
  </div>
)

export default PayerQualityofAccessPage
