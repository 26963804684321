import React from 'react'

import Layout from '../../../components/Layout'
import RequestDemoCtaBlock from '../../../components/RequestDemoCtaBlock'

import PayerHero from './PayerHero'
import PayerManagement from './PayerManagement'
import PayerAccounts from './PayerAccounts'

const LayoutPayer = () => (
  <Layout>
    <PayerHero />
    <PayerManagement />
    <PayerAccounts />
    <RequestDemoCtaBlock />
  </Layout>
)

export default LayoutPayer
