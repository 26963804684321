import React from 'react'

import ProductHero from '../../../../components/ProductHero'

import toolContent from '../../../../content/toolContent'
import assets from '../../../../content/assets'

const PayerHero = () => (
  <div>
    <ProductHero
      backgroundImage={assets.images.fluidBubblesPrimary3}
      image={toolContent.heroToolImage.payer}
      imageAltText="Payer Quality of Access Tool Image"
      icon={toolContent.iconWhite.payer}
      title={toolContent.title.payer}
      description={toolContent.description.payer}
    />
  </div>
)

export default PayerHero
