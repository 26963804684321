import React from 'react'

import { Flex } from '@rebass/grid/emotion'
import { GridContainer } from '../../../../components/Grid'
import { ValueListContainer, ValueListItem } from '../../../../components/ValueList'
import QuestionBlock from '../../../../components/QuestionBlock'
import {
  SectionDashboardDescription,
  SectionDashboardQuestions,
  SectionDashboardContainer,
  SectionDashboardContentContainer,
} from '../../../../components/SectionDashboard'

import { Colors } from '../../../../utils/styles'

const payerManagementColor = Colors.PRIMARY
const payerDashboardName = 'Management Dashboard'
const payerDashboardDescription = `
  To support quality of coverage restrictions profiling, Pulse Analytics can perform deep
  account profiling for analogs across dominant channels, pharmacy & medical benefit & Part B
  MACs.
`

const payerManagementValues = (
  <ValueListContainer>
    <ValueListItem
      iconColor={payerManagementColor}
      text="Inform payer pre-launch access forecasting & post-launch surveillance"
    />
    <ValueListItem
      iconColor={payerManagementColor}
      text="Support payer account restrictions management & targeting"
    />
    <ValueListItem
      iconColor={payerManagementColor}
      text="Track account manager engagement efforts & market changes"
    />
  </ValueListContainer>
)

const payerManagementQuestions = (
  <Flex flexWrap="wrap">
    <QuestionBlock
      title="Review Timing"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565364090/pulse-analytics-marketing/icons/payer-mgmt-review-timing.svg"
      // eslint-disable-next-line
      question="How quickly have payers reviewed & published coverage policies for my product vs. relevant analogs?"
      color={payerManagementColor}
    />
    <QuestionBlock
      title="Quality of Access"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565364090/pulse-analytics-marketing/icons/payer-mgmt-quality-access.svg"
      // eslint-disable-next-line
      question="How is my product being managed relative to my approved indications & which restrictions are most burdensome?"
      color={payerManagementColor}
    />
    <QuestionBlock
      title="Historical Trends"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565364090/pulse-analytics-marketing/icons/payer-mgmt-historical-trends.svg"
      question="How has my coverage changed month over month?"
      color={payerManagementColor}
    />
    <QuestionBlock
      title="Competitive Access"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565364200/pulse-analytics-marketing/icons/payer-mgmt-competitive-access.svg"
      question="How does my coverage compare vs. competitors?"
      color={payerManagementColor}
    />
    <QuestionBlock
      title="Regional Targeting"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565364090/pulse-analytics-marketing/icons/payer-mgmt-regional-targeting.svg"
      question="Which states do we have favorable vs. restricted access?"
      color={payerManagementColor}
    />
  </Flex>
)

const PayerManagement = () => (
  <SectionDashboardContainer>
    <SectionDashboardContentContainer color={payerManagementColor}>
      <GridContainer flexWrap="wrap" style={{ padding: 0 }}>
        <SectionDashboardDescription
          title={payerDashboardName}
          description={payerDashboardDescription}
          values={payerManagementValues}
        />
        <SectionDashboardQuestions
          color={payerManagementColor}
          questions={payerManagementQuestions}
          subtitle={payerDashboardName}
        />
      </GridContainer>
    </SectionDashboardContentContainer>
  </SectionDashboardContainer>
)

export default PayerManagement
