import React from 'react'

import { Flex } from '@rebass/grid/emotion'
import { GridContainer } from '../../../../components/Grid'
import { ValueListContainer, ValueListItem } from '../../../../components/ValueList'
import QuestionBlock from '../../../../components/QuestionBlock'
import {
  SectionDashboardDescription,
  SectionDashboardQuestions,
  SectionDashboardContainer,
  SectionDashboardContentContainer,
} from '../../../../components/SectionDashboard'

import { Colors } from '../../../../utils/styles'

const payerAccountsColor = Colors.SECONDARY
const payerDashboardName = 'Account Profiles'
const payerDashboardDescription = `
  In-depth account profiles for top payers to allow monitoring of quality
  of access as well as support for regional targeting & strategic account engagement.
`

const payerAccountsValues = (
  <ValueListContainer>
    <ValueListItem
      iconColor={payerAccountsColor}
      text="Inform targeted account engagement strategies to secure favorable payer access"
    />
    <ValueListItem
      iconColor={payerAccountsColor}
      text="Focus efforts on account planning rather than profiling"
    />
    <ValueListItem
      iconColor={payerAccountsColor}
      text="Maintain updated account business model, partnerships & management capability trends"
    />
    <ValueListItem
      iconColor={payerAccountsColor}
      text="Inform impact of payer coverage decisions"
    />
  </ValueListContainer>
)

const payerAccountsQuestions = (
  <Flex flexWrap="wrap">
    <QuestionBlock
      title="Account Summary"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565364560/pulse-analytics-marketing/icons/payer-accounts-account-summary.svg"
      // eslint-disable-next-line
      question="What are the account’s quality of coverage, contracting precedent, & who are the key influencers?"
      color={payerAccountsColor}
    />
    <QuestionBlock
      title="Organization Overview"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565364560/pulse-analytics-marketing/icons/payer-accounts-organization-overview.svg"
      question="What is the payer’s business model, enrollment & geographic footprint?"
      color={payerAccountsColor}
    />
    <QuestionBlock
      title="Management Capabilities"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565364560/pulse-analytics-marketing/icons/payer-accounts-management-capabilities.svg"
      question="What are the account’s emerging management capabilities & provider partnerships?"
      color={payerAccountsColor}
    />
    <QuestionBlock
      title="Review Process"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565364560/pulse-analytics-marketing/icons/payer-accounts-review-process.svg"
      question="How does the payer review products & what are default coverage policies pre-review?"
      color={payerAccountsColor}
    />
    <QuestionBlock
      title="Product Coverage"
      // eslint-disable-next-line
      icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565364560/pulse-analytics-marketing/icons/payer-accounts-product-coverage.svg"
      question="Which states do we have favorable vs. restricted access?"
      color={payerAccountsColor}
    />
  </Flex>
)

const PayerAccounts = () => (
  <SectionDashboardContainer>
    <SectionDashboardContentContainer color={payerAccountsColor}>
      <GridContainer flexWrap="wrap" style={{ padding: 0 }}>
        <SectionDashboardDescription
          title={payerDashboardName}
          description={payerDashboardDescription}
          values={payerAccountsValues}
        />
        <SectionDashboardQuestions
          color={payerAccountsColor}
          questions={payerAccountsQuestions}
          subtitle={payerDashboardName}
        />
      </GridContainer>
    </SectionDashboardContentContainer>
  </SectionDashboardContainer>
)

export default PayerAccounts
